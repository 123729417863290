$(document).ready(function () {
    if ($.trim($(".list-questions").html()).length === 0) {
        $('.btn-add-question').trigger('click')
    }
    $('.question-content').on('input', function () {
        let indexElement = $(this).closest('.card-question').attr('data-index')
        let previewElement = $('#preview-message .chat-box-body .list-message-preview')
        let messageContent = previewElement.find(`.message-item[data-index=${indexElement}]`).find('.chat-box-message .message-content')
        messageContent.text($(this).val())
    })
})

$(document).on('cocoon:after-insert','.list-questions',function (e, insertedItem) {
    sortIndex()
    previewSurvey(insertedItem)
})

$(document).on('cocoon:after-remove','.list-questions',function (e, insertedItem) {
    console.log('a')
    let indexQuestion = insertedItem.attr('data-index')
    $(`#preview-message .chat-box-body .list-message-preview .message-item[data-index=${indexQuestion}]`).remove()
    sortIndex()
})

$(document).on('click', '.btn-add-answer', function () {
    previewAnswer($(this))
})

$(document).on('input', '.input-answer', function (e) {
    $(this).parent().find('.text-insert-answer-error').remove()
    $(this).closest('.card-question').find('.btn-add-answer').removeClass('disabled')

    if (checkExistValue($(this))) {
        if ($(this).parent().find('.text-insert-answer-error').length === 0) {
            $('<span class="text-danger text-insert-answer-error">登録済みです</span>').insertAfter($(this))
        }
        $(this).closest('.card-question').find('.btn-add-answer').addClass('disabled')
    }
})

$(document).on('keypress', '.input-answer', function (e) {
    if (e.which === 13) {
        e.preventDefault()
        if (!checkExistValue($(this))) {
            previewAnswer($(this))
        }
    }
})

$(document).on('change', '.question-answer-type', function (e) {
    let questionNested = $(this).closest('.card-question')
    let indexQuestion = questionNested.attr('data-index')
    let previewElement = $(`#preview-message .chat-box-body .list-message-preview .message-item[data-index="${indexQuestion}"] .list-selection`)
    console.log($(`#preview-message .chat-box-body .list-message-preview .message-item[data-index="${indexQuestion}"]`))
    if ($(this).val() === 'text') {
        questionNested.find('#option-selection').addClass('d-none')
        previewElement.addClass('d-none').removeClass('d-flex')
        questionNested.find('#option-selection #previewArea input').prop('disabled', true)
    } else {
        questionNested.find('#option-selection').removeClass('d-none')
        previewElement.removeClass('d-none').addClass('d-flex')
        questionNested.find('#option-selection #previewArea input').prop('disabled', false)
    }
})

$(document).on('click', '.remove-answer', function () {
    let previewAnswer = $(this).closest('.preview-answer')
    let indexAnswer = previewAnswer.attr('data-index')
    let nameInputDestroy = previewAnswer.find('input.input-preview-answer').attr('name').replace('[answer_text]', '[_destroy]')
    if(!checkExistValue($(this))){
        $(this).closest('.card-question').find('.text-insert-answer-error').remove()
        $(this).closest('.card-question').find('.btn-add-answer').removeClass('disabled')
    }
    $(`#preview-message .chat-box-body .list-message-preview .list-selection .btn-preview-selection[data-index=${indexAnswer}]`).remove()
    $('#previewArea').append('<input type="hidden" class="" name="' + nameInputDestroy + '" value="1">')
    previewAnswer.remove();
})


$(document).on('click', '#delete-survey', function () {
    var data = $('.survey-checkbox').serialize();
    $.ajax({
        url: '/surveys/delete_multiple_survey',
        type: 'DELETE',
        data: data,
        success: function (data) {
            toastr.success(data.message)
            $.each(data.survey_ids, function (index, value) {
                $('#survey-' + value + '').remove()
            })
        },
        error: function (error) {
            toastr.error(error.message)
        }
    });

})

function checkExistValue(input) {
    let questionNested = input.closest('.card-question')
    let userInput = input.val();
    return questionNested.find('.input-preview-answer').filter((index, item) => $(item).val() === userInput).length > 0
}

function previewAnswer(elementClick) {
    let indexElement = $.now()
    let previewElement = $('#preview-message .chat-box-body .list-message-preview')
    let questionNested = elementClick.closest('.card-question')
    let indexQuestion = questionNested.attr('data-index')
    let userInput = questionNested.find('#input-answer').val();
    let countPreview = questionNested.find(".preview-answer").length;
    let nameInputDestroy = questionNested.find('input.question-content').attr('name').replace('[content]', '[answers_attributes][' + indexElement + '][answer_text]')
    if (userInput.trim() !== '' && countPreview < 10) {
        let newButton = $(
            '<div class="preview-answer mr-2" data-index="' + indexElement + '">' +
            '<div class="text-white">' + userInput + '</div>' +
            '<span class="remove-answer cursor-pointer">' +
            '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M2.81767 2.81767C3.01924 2.61611 3.34603 2.61611 3.54759 2.81767L7.99984 7.26992L12.4521 2.81767C12.6536 2.61611 12.9804 2.61611 13.182 2.81767C13.3836 3.01924 13.3836 3.34603 13.182 3.54759L8.72975 7.99984L13.182 12.4521C13.3836 12.6536 13.3836 12.9804 13.182 13.182C12.9804 13.3836 12.6536 13.3836 12.4521 13.182L7.99984 8.72975L3.54759 13.182C3.34603 13.3836 3.01924 13.3836 2.81767 13.182C2.61611 12.9804 2.61611 12.6536 2.81767 12.4521L7.26992 7.99984L2.81767 3.54759C2.61611 3.34603 2.61611 3.01924 2.81767 2.81767Z" fill="#475569"/>\n' +
            '</svg>' +
            '</span>' +
            '<input type="hidden" class="input-preview-answer" name="' + nameInputDestroy + '" value="' + userInput + '">' +
            '</div>'
        );
        let answerPreview =
            `<button class="btn-preview-selection px-2 py-1 mr-1" data-index="${indexElement}">
                      ${userInput}
                    </button>`
        questionNested.find('#previewArea').append(newButton);
        questionNested.find('#input-answer').val('');

        if (previewElement.find(`.message-item[data-index=${indexQuestion}]`).find(`.list-selection .btn-preview-selection[data-index=${indexElement}]`).length === 0) {
            previewElement.find(`.message-item[data-index=${indexQuestion}]`).find('.list-selection').append(answerPreview)
        }
        if (countPreview === 9) {
            questionNested.find('.btn-add-answer').addClass('disabled')
        }
    }
}

function previewSurvey(insertedItems) {
    insertedItems.each(function (index, value) {
        let insertedItem = $(value)
        let indexElement = insertedItem.attr('data-index') || $.now()
        insertedItem.attr('data-index', indexElement)
        let previewElement = $('#preview-message .chat-box-body .list-message-preview')
        let sectionHtml = ` 
            <div class="message-item w-16  w-100 " data-index="${indexElement}">
            <div class="d-flex gap-7">
              <div class="icon">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z" fill="#F9D2E4"/>
                  <path d="M15 7.5C13.035 7.5 11.4375 9.0975 11.4375 11.0625C11.4375 12.99 12.945 14.55 14.91 14.6175C14.97 14.61 15.03 14.61 15.075 14.6175C15.09 14.6175 15.0975 14.6175 15.1125 14.6175C15.12 14.6175 15.12 14.6175 15.1275 14.6175C17.0475 14.55 18.555 12.99 18.5625 11.0625C18.5625 9.0975 16.965 7.5 15 7.5Z" fill="#E54D93"/>
                  <path d="M18.8097 16.6125C16.7172 15.2175 13.3047 15.2175 11.1972 16.6125C10.2447 17.25 9.71973 18.1125 9.71973 19.035C9.71973 19.9575 10.2447 20.8125 11.1897 21.4425C12.2397 22.1475 13.6197 22.5 14.9997 22.5C16.3797 22.5 17.7597 22.1475 18.8097 21.4425C19.7547 20.805 20.2797 19.95 20.2797 19.02C20.2722 18.0975 19.7547 17.2425 18.8097 16.6125Z" fill="#E54D93"/>
                </svg>
              </div>
              <div class='chat-box-message w-100'>
                 <div class="message-content text-break"></div>
              </div>
            </div>
             <div class=' mt-3 mb-2 '>
                <div class='d-flex gap-7 list-selection'></div>
             </div>
          </div>`
        if (previewElement.find(`.message-item[data-index=${indexElement}]`).length === 0) {
            previewElement.append(sectionHtml)
        }
    })
    $('.question-content').on('input', function () {
        let indexElement = $(this).closest('.card-question').attr('data-index')
        let previewElement = $('#preview-message .chat-box-body .list-message-preview')
        let messageContent = previewElement.find(`.message-item[data-index=${indexElement}]`).find('.chat-box-message .message-content')
        messageContent.text($(this).val())
    })
}

function sortIndex(){
    $('.list-questions .card-question').not(':hidden').each(function (index, element) {
        $(element).find('.index-question').text(index+1)
    })
}