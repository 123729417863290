$(document).ready(function () {
    var container = $('.list-label');
    $('.next-label').on('click', function () {
        $('.previous-label').removeClass('disabled')
        let currentScroll = container.scrollLeft();
        let maxScroll = container[0].scrollWidth - container.width();
        if (currentScroll < maxScroll) {
            container.animate({scrollLeft: currentScroll + 300}, 500);
            if (currentScroll + 300 >= maxScroll) {
                $('.next-label').addClass('disabled')
            }
        }
    });
    $('.previous-label').on('click', function () {
        $('.next-label').removeClass('disabled')
        let currentScroll = container.scrollLeft();
        if (currentScroll > 0) {
            container.animate({scrollLeft: currentScroll - 300}, 500);
            console.log(container.scrollLeft())
            if (container.scrollLeft() <= 300) {
                $('.previous-label').addClass('disabled')
            }
        }
    })
})