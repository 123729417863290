$(() => {
    $('.type-broadcast-select').change(function () {
        var selectedValue = $(this).val();
        var modal = $(this).closest('.modal-boardcasts')
        if (selectedValue == 1) {
            modal.find('.boardcast-package').removeClass('d-none');
            modal.find('.boardcast-survey').addClass('d-none');
        } else {
            modal.find('.boardcast-package').addClass('d-none');
            modal.find('.boardcast-survey').removeClass('d-none');
        }
    });

    $('.type-broadcast-select').change();

    $('input[name="target"]').change(function () {
        var selectedValue = $('input[name="target"]:checked').val();
        var modal = $(this).closest('.modal-boardcasts')
        if (selectedValue == 1) {
            modal.find('.boardcast-tag').removeClass("d-none");
        } else {
            modal.find('.boardcast-tag').addClass("d-none");
        }
    });

    $('input[name="target"]:checked').change();
    $('input[name="boardcast[target]"]:checked').change();

    $('.btn-create-boardcast').click(function (e) {
        var name = $('#create_boardcast_name').val();
        var modal = $(this).closest('.modal-boardcasts')
        if (name === "") {
            modal.find('.boardcast-error').removeClass('d-none');
            setTimeout(function () {
                modal.find('.boardcast-error').addClass('d-none');
            }, 3000);
            e.preventDefault();
        }
    });

    $('.btn-update-boardcast').click(function (e) {
        var id = $(this).data('id');
        var modalEdit = $(this).closest('.modal-edit-boardcasts')
        var name = $('.boardcast-' + id).val();
        var type_boardcast = modalEdit.find('#boardcast_type_boardcast').val();
        var boardcast_package_id = modalEdit.find('#boardcast_package_id').val();
        var boardcast_survey_id = modalEdit.find('#boardcast_survey_id').val();
        var boardcast_target = modalEdit.find('.target-boardcasts:checked').val();
        var datetime_boardcasts = modalEdit.find('.dateime-boardcasts').val();
        var tag_ids_boardcasts = modalEdit.find('.boardcasts_tag_ids').val();

        if (name === "") {
            $('.boardcast-error-' + id).removeClass('d-none');
            e.preventDefault();
            setTimeout(function () {
                $('.boardcast-error-' + id).addClass('d-none');
            }, 3000);
        } else {
            e.preventDefault();
            $.ajax({
                url: "/boardcasts/" + id,
                method: "PATCH",
                data: {
                  boardcast: {
                    name: name,
                    type_boardcast: type_boardcast,
                    survey_id: boardcast_survey_id,
                    package_id: boardcast_package_id,
                    start_date: datetime_boardcasts,
                    tag_ids: tag_ids_boardcasts,
                    target: boardcast_target
                  }
                  },
                dataType: "json",
                success: function (response) {
                    if (response.success) {
                        window.location.href = "/boardcasts";
                    } else {
                        $('.error-modal').text("タグが既に存在しています。");
                        setTimeout(function () {
                            $('.error-modal').hide();
                        }, 3000);
                    }
                },
                error: function (xhr) {
                    window.location.href = "/boardcasts";
                },
            });
        }
    });

    $(".delete-boardcast").click(function () {
        deleteSelectedRecords();
    });

    $(".select-all").change(function () {
        $(".record-checkbox").prop("checked", this.checked);
    });

    $(".record-checkbox").change(function () {
        all = $(".record-checkbox").length;
        count = $(".record-checkbox:checked").length;
        if (all == count) {
            $(".select-all").prop("checked", true);
        } else {
            $(".select-all").prop("checked", false);
        }
    });

    function deleteSelectedRecords() {
        var selectedIds = [];
        $(".record-checkbox:checked").each(function () {
            selectedIds.push($(this).val());
        });
        console.log(selectedIds);
        if (selectedIds.length > 0) {
            if (selectedIds) {
                $.ajax({
                    url: "/boardcasts/delete_boardcast",
                    method: "post",
                    data: {selected_ids: selectedIds.join(",")},
                    dataType: "script"
                });
            }
        }
    }
});
$(document).on("change", 'input[name="boardcast[target]"]', function (e) {
    if (this.checked) {
        var selectedValue = $(this).val();
        if (selectedValue == 1) {
            $(this).closest('.modal-body').find('.boardcast-tag').removeClass("d-none");
        } else {
            $(this).closest('.modal-body').find('.boardcast-tag').addClass("d-none");
        }
    }
})