$(document).ready(function () {
    $(".package-checkbox-all").change(function () {
        $(".package-checkbox-record").prop("checked", this.checked);
        $('.package-checkbox-mobile-all').prop("checked", this.checked);
        if (this.checked) {
            $('.package-preview-mobile').addClass('active')
            $('.btn-package').prop("disabled", false);
        } else {
            $('.package-preview-mobile').removeClass('active')
            $('.btn-package').prop("disabled", true);
        }
    });

    $('.package-checkbox-mobile-all').on('click', function () {
        $(".package-checkbox-all").trigger('click')
    })
    $('.package-preview-mobile').on('click', function () {
        $(`tr#package-${$(this).data('package-id')}`).find('input.package-checkbox-record').trigger('click')
    })

    $(".package-checkbox-record").change(function () {
        let packageMobile = $(`.package-mobile-${$(this).val()}`)
        if (this.checked) {
            packageMobile.addClass('active')
            $('.btn-package').prop("disabled", false);
        } else {
            packageMobile.removeClass('active')
        }
        let all = $(".package-checkbox-record").length;
        let count = $(".package-checkbox-record:checked").length;
        if (all === count) {
            $(".package-checkbox-all").prop("checked", true);
            $('.package-checkbox-mobile-all').prop("checked", true);
        } else {
            $(".package-checkbox-all").prop("checked", false);
            $('.package-checkbox-mobile-all').prop("checked", false);
        }
        if (count >= 1){
            $('.btn-package').prop("disabled", false);
        }
        else {
            $('.btn-package').prop("disabled", true);
        }
    });
})