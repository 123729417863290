$(document).on('change', '.radio-period-send-time', function () {
    let nestedButton = $(this).closest('#click-time-reaction')
    if (this.value === "0") {
        nestedButton.find('.reaction-period').addClass('d-none').removeClass('d-flex')
        nestedButton.find('.reaction-period input').prop('disabled', true);
    } else if (this.value === "1") {
        nestedButton.find('.reaction-period').removeClass('d-none').addClass('d-flex')
        nestedButton.find('.reaction-period input').prop('disabled', false);
    }
})
$(document).on('click', '.btn-add-keyword', function () {
    previewKeyword($(this))
})
$(document).on('keypress', '.input-keyword', function (e) {
    if (e.which === 13) {
        e.preventDefault()
        previewKeyword($(this))
    }
})
$(document).on('click', '.remove-keyword', function () {
    if ($(this).data('condition')) {
        $(this).closest('.preview-keyword').hide()
        $(this).closest('.preview-keyword').find('.input-preview-keyword').val('')
    } else {
        $(this).closest('.preview-keyword').remove()
    }

})
$(document).on('click', '#archive-reaction', function () {
    let data = $('.package-checkbox').serialize();
    if (data) {
        $.ajax({
            url: '/reactions/destroy_multiple',
            type: 'DELETE',
            data: data,
            success: function (data) {
                toastr.success(data.message)
                $.each(data.reaction_ids, function (index, value) {
                    $('#reaction-' + value + '').remove()
                    $('.reaction-mobile-' + value + '').remove()
                })
            },
            error: function (error) {
                toastr.error(error.responseJSON.message)
            }
        });
    }
})
$(document).on('click', '#submitFormChangeLabelReaction', function () {
    let data = $('.package-checkbox:checked').serialize();
    let label_id = $(this).closest('#changeLabelReaction').find('select#label_id').serialize()

    if (data) {
        $.ajax({
            url: '/reactions/change_label',
            type: 'POST',
            data: data + "&" + label_id + "",
            success: function (data) {
                $('#changeLabelReaction').find('.btn-close-modal-reaction').click()
                toastr.options.timeOut = 1000;
                toastr.options.onHidden = function () {
                    window.location.href = window.location.pathname
                }
                toastr.success(data.message)
            },
            error: function (error) {
                toastr.error(error.responseJSON.message)
            }
        })

    }
})
$(document).on('click', '#duplicate-reaction', function () {
    let data = $('.package-checkbox:checked').serialize();
    if (data) {
        $.ajax({
            url: '/reactions/duplicate',
            type: 'POST',
            data: data,
            success: function (data) {
                toastr.options.timeOut = 1000;
                toastr.options.onHidden = function () {
                    window.location.href = window.location.pathname
                }
                toastr.success(data.message)
            },
            error: function (error) {
                toastr.error(error.responseJSON.message)
            }
        });

    }
})
$(document).on('change', '.period-time-start', function () {
    $('.period-time-end').attr('min', $(this).val())
})
$(document).on('change', '.period-time-end', function () {
    $('.period-time-start').attr('max', $(this).val())
})
$(document).on('cocoon:after-insert', '.list-trigger-reaction', function (e, insertedItem) {
    if ($(this).hasClass('d-none')) {
        $(this).removeClass('d-none')
    }
})

$(document).on('cocoon:after-remove', '.list-trigger-reaction', function (e, insertedItem) {
    if ($(this).find('.trigger-item').length === 0) {
        $(this).addClass('d-none')
    }
})


function previewKeyword(elementClick) {
    let indexElement = $.now()
    let questionNested = elementClick.closest('#reactionCondition')
    let userInput = questionNested.find('#input-keyword').val();
    let nameInputDestroy = 'reaction[condition_attributes][keywords][]'
    if (userInput.trim() !== '') {
        let newButton = $(
            '<div class="preview-keyword mr-2" data-index="' + indexElement + '">' +
            '<div class="text-white">' + userInput + '</div>' +
            '<span class="remove-keyword cursor-pointer">' +
            '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M2.81767 2.81767C3.01924 2.61611 3.34603 2.61611 3.54759 2.81767L7.99984 7.26992L12.4521 2.81767C12.6536 2.61611 12.9804 2.61611 13.182 2.81767C13.3836 3.01924 13.3836 3.34603 13.182 3.54759L8.72975 7.99984L13.182 12.4521C13.3836 12.6536 13.3836 12.9804 13.182 13.182C12.9804 13.3836 12.6536 13.3836 12.4521 13.182L7.99984 8.72975L3.54759 13.182C3.34603 13.3836 3.01924 13.3836 2.81767 13.182C2.61611 12.9804 2.61611 12.6536 2.81767 12.4521L7.26992 7.99984L2.81767 3.54759C2.61611 3.34603 2.61611 3.01924 2.81767 2.81767Z" fill="#475569"/>\n' +
            '</svg>' +
            '</span>' +
            '<input type="hidden" class="input-preview-keyword" name="' + nameInputDestroy + '" value="' + userInput + '">' +
            '</div>'
        );
        questionNested.find('#previewAreaKeyword').append(newButton);
        questionNested.find('#input-keyword').val('');
    }
}
