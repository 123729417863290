setTimeout(function () {
  var list_flash = document.getElementsByClassName('flash_message');
  for (let i = 0; i < list_flash.length; i++) {
      list_flash[i].remove();
  }
},3000);
setTimeout(function () {
    var list_flash = document.getElementsByClassName('flash-login');
    for (let i = 0; i < list_flash.length; i++) {
      list_flash[i].remove();
    }
  },3000);

