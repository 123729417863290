/* eslint no-console:0 */
import Rails from '@rails/ujs'
import * as ActiveStorage from "@rails/activestorage"
import 'jquery'
import 'popper.js'
import 'bootstrap'
import "@fortawesome/fontawesome-free/js/all";
import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme'
require('admin-lte');
// require('@nathanvda/cocoon')
import "./custom/cocoon.js";
import 'stylesheets/base'
import "stylesheets/login";
import "stylesheets/labels";
import "stylesheets/modal_label";
import "stylesheets/dashboard";
import "stylesheets/linked_account";
import "stylesheets/survey";
import "stylesheets/client";
import "stylesheets/preview";
import "stylesheets/package";
import "./flash/flash.js";
import "./label/modal.js";
import "./label/destroy.js";
import "./tag/destroy.js";
import "./tag/modal.js";
import "./sidebar/sidebar_menu.js";
import 'stylesheets/responsive'
import 'stylesheets/date'
import 'daterangepicker/daterangepicker';
import moment from 'moment';
window.moment = moment;
import "./survey/survey.js";
import "./custom/custom.js";
import "./search_user/search";
import 'stylesheets/select2'
import "stylesheets/sidebar";
import "stylesheets/header";
import "stylesheets/footer";
import "stylesheets/plan";
import "stylesheets/term";
import "stylesheets/instagram_clients";
import "./preview/preview.js";
import "./package/scroll_label.js";
import "./package/checkbox_package.js";
import "./package/package_cocoon.js";
import "./package/package.js";
import "./instagram_users/index.js";
import 'stylesheets/toastr.scss';
import 'stylesheets/signup.scss';
import "./signup/signup.js";
import "./reaction/reaction.js";
import 'stylesheets/toastr.scss'
import 'stylesheets/reaction.scss'
import 'stylesheets/boardcasts.scss'
import 'stylesheets/contract.scss'
import "./homes/index.js";
import "./boardcasts/index.js";
import "./package/cocoon.js";
import "./package/input.js";
import toastr from 'toastr'
toastr.options = {
    "progressBar": true
};
window.toastr = toastr

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
ActiveStorage.start()
