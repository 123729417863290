$(document).ready(function () {

    $('#goto-archive').on('click', function () {
        let data = $('.package-checkbox').serialize();
        if (data) {
            $.ajax({
                url: '/packages/check_package_used',
                type: 'POST',
                data: data
            });
        }
    })
    $('#submitFormChangeLabel').on('click', function () {
        let data = $('.package-checkbox:checked').serialize();
        let label_id = $(this).closest('#changeLabel').find('select#label_id').serialize()

        if (data) {
            $.ajax({
                url: '/packages/change_label',
                type: 'POST',
                data: data + "&" + label_id + "",
                success: function (data) {
                    $('#changeLabel').find('.btn-close-modal').click()
                    toastr.options.timeOut = 1000;
                    toastr.options.onHidden = function () {
                        window.location.href = window.location.pathname
                    }
                    toastr.success(data.message)
                },
                error: function (error) {
                    toastr.error(error.responseJSON.message)
                }
            })

        }
    })
    $('#duplicate-package').on('click', function () {
        let data = $('.package-checkbox:checked').serialize();
        if (data) {
            $.ajax({
                url: '/packages/duplicate',
                type: 'POST',
                data: data,
                success: function (data) {
                    toastr.options.timeOut = 1000;
                    toastr.options.onHidden = function () {
                        window.location.href = window.location.pathname
                    }
                    toastr.success(data.message)
                },
                error: function (error) {
                    toastr.error(error.responseJSON.message)
                }
            });

        }
    })
    $('#formPackage').on('submit', function (e) {
        if ($(this).find('.list-section .package-item').length === 0) {
            if ($('.text-package-error').length === 0) {
                $('<span class="text-danger float-right text-package-error">1つ以上コンポーネントを設定する必要があります</span>').insertAfter($('.input-name-package'))
            }
            e.preventDefault()
        }
    })
});