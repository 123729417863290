$(() => {
  $('.btn-update').click(function(e) {
    var id = $(this).data('id');
    var name = $('.label-' + id).val();
    if (name === ""){
      $('.label-error-' + id).removeClass('d-none');
      e.preventDefault();
    }
  });

  $('.btn-create').click(function(e) {
    var name = $('#create_label_name').val();
    if (name === ""){
      $('.label-error').removeClass('d-none');
      e.preventDefault();
    }
  });
});
