$(() => {
    $(".delete-label").click(function (e) {
        deleteSelectedRecords();
    });

    function deleteSelectedRecords() {
        var selectedIds = [];
        let controller_name = $('.record-checkbox').attr('data-name');
        $(".record-checkbox:checked").each(function () {
            selectedIds.push($(this).val());
        });
        let redirect_url = controller_name === "package" ? "/packages/labels?type=package" : "/reactions/labels?type=reaction"
        if (selectedIds.length > 0) {
            var confirmation = confirm("選択しているラベルを削除しますか？削除後に元に戻せません！");
            if (confirmation) {
                $.ajax({
                    url: "/packages/labels/delete",
                    method: "post",
                    data: {selected_ids: selectedIds.join(","), name: controller_name},
                    dataType: "json",
                    success: function (response) {
                      $(".record-checkbox:checked").prop("checked", false);
                      toastr.options.timeOut = 1000;
                      toastr.options.onHidden = function () {
                        window.location.href = redirect_url
                      }
                        toastr.success(response.message)
                    },
                    error: function (response) {
                      toastr.options.timeOut = 1000;
                      toastr.options.onHidden = function () {
                        window.location.href = redirect_url
                      }
                        toastr.error(response.responseJSON.message)
                    },
                })
            }
        }
    }
});