$(document).ready(function () {
    if ($.trim($(".list-section").html()).length === 0) {
        $('.btn-add-section').trigger('click')
    }
    setDataLinkAddAssociation()
    previewPackage($('.package-item'))
})
$(document).on('cocoon:before-remove', '.list-section', function (e, insertedItem) {
    let sectionNested = insertedItem.closest('.section-item')
    if (insertedItem.hasClass('package-item')) {
        if (insertedItem.closest('.list-section').find('.package-item').length <= 3) {
            $('.btn-add-image').removeClass('disabled')
            $('.btn-add-section').removeClass('disabled')
        }
    }
    if (insertedItem.hasClass('button-item')) {
        if (insertedItem.closest('.list-options').find('.button-item').length <= 1) {
            sectionNested.find('.btn-add-selection').removeClass('d-none')
            sectionNested.find('.btn-add-button').removeClass('d-none')
        }
    }
    if (insertedItem.hasClass('option-button')) {
        if (insertedItem.closest('.list-options').find('.option-button').length <= 3) {
            sectionNested.find('.btn-add-button').removeClass('disabled')
        }
    }
    if (insertedItem.hasClass('option-selection')) {
        if (insertedItem.closest('.list-options').find('.option-selection').length <= 10) {
            sectionNested.find('.btn-add-selection').removeClass('disabled')
        }
        if (insertedItem.closest('.list-options').find('.option-selection').length === 1 && insertedItem.closest('.list-section').find('.package-item').length <= 3) {
            $('.btn-add-image').removeClass('disabled')
            $('.btn-add-section').removeClass('disabled')
        }
    }

    if (insertedItem.hasClass('step-item')) {
        if (insertedItem.closest('.list-steps').find('.step-item').length <= 2) {
            insertedItem.closest('.list-options').find('.button-add-step').removeClass('d-none')
        }
    }
})
$(document).on('cocoon:after-remove', '.list-section', function (e, insertedItem) {
    removePreviewPackage(insertedItem)
})
$(document).on('cocoon:before-insert', '.list-section', function (e, insertedItem, event) {
    let elementClick = $(event.currentTarget)
    if (insertedItem.hasClass('package-item')) {
        if ($('.list-section').find('.package-item').length >= 3) {
            e.preventDefault()
        }
    }
    if (insertedItem.hasClass('option-button')) {
        if(elementClick.closest('.package-item').find('.list-options .option-button').length >= 3){
            e.preventDefault()
        }
    }
    if (insertedItem.hasClass('option-selection')) {
        if(elementClick.closest('.package-item').find('.list-options .option-selection').length >= 10){
            e.preventDefault()
        }
    }
    if (insertedItem.hasClass('step-item')) {
        if(elementClick.closest('.button-item').find('.list-steps .step-item').length >= 2){
            e.preventDefault()
        }
    }
})
$(document).on('cocoon:after-insert', '.list-section', function (e, insertedItem) {
    let sectionNested = $(insertedItem).closest('.section-item')
    $('.text-package-error').remove()
    if ($(this).find('.package-item').length === 3 || insertedItem.hasClass('option-selection')) {
        $('.btn-add-image').addClass('disabled')
        $('.btn-add-section').addClass('disabled')
    }
    if (insertedItem.hasClass('option-button')) {
        sectionNested.find('.btn-add-selection').addClass('d-none')
        if (insertedItem.closest('.list-options').find('.option-button').length === 3) {
            sectionNested.find('.btn-add-button').addClass('disabled')
        }
    }
    if (insertedItem.hasClass('option-selection')) {
        sectionNested.find('.btn-add-button').addClass('d-none')
        if (insertedItem.closest('.list-options').find('.option-selection').length === 10) {
            sectionNested.find('.btn-add-selection').addClass('disabled')
        }
    }
    if (insertedItem.hasClass('step-item')) {
        if (insertedItem.closest('.list-steps').find('.step-item').length === 2) {
            insertedItem.closest('.list-options').find('.button-add-step').addClass('d-none')
        }
    }
    setDataLinkAddAssociation()
    previewPackage(insertedItem)
})

$(document).on('change', '.click-type-button', function () {
    let nestedButton = $(this).closest('.button-item')
    nestedButton.find('.click-button').addClass('d-none')
    nestedButton.find('.click-button input').prop('disabled', true);
    nestedButton.find('.click-button select').prop('disabled', true);
    nestedButton.find(`#click-type-${this.value}`).removeClass('d-none')
    nestedButton.find(`#click-type-${this.value} input`).prop('disabled', false);
    nestedButton.find(`#click-type-${this.value} select`).prop('disabled', false);
    if (this.value === 'package') {
        if (nestedButton.find('#click-type-package .radio-package-send-time:checked').val() === '0') {
            nestedButton.find('#click-type-package .input-search-client').prop('disabled', true);
        }
    }
})
$(document).on('change', '.radio-package-send-time', function () {
    let nestedButton = $(this).closest('#click-type-package')
    if (this.value === "immediately") {
        nestedButton.find('.package-time-lag').addClass('d-none')
        nestedButton.find('.package-time-lag select').prop('disabled', true);
    } else if (this.value === "time_lag") {
        nestedButton.find('.package-time-lag').removeClass('d-none')
        nestedButton.find('.package-time-lag select').prop('disabled', false);
    }
})
$(document).on('click', '.select-image', function () {
    $(this).closest('.image-item').find('.input-select-image').trigger('click')
})
$(document).on('change', 'input.input-select-image', function (event) {
    let reader = new FileReader();
    let file = event.target.files[0]
    let indexElement = $(this).closest('.package-item').attr('data-index')
    let previewElement = $('#preview-message .chat-box-body .list-message-preview')
    let imageItem = $(this).closest('.image-item')
    let chatBox = previewElement.find(`.message-item[data-index=${indexElement}]`).find('.chat-box-message')
    if (file) {
        let filesize = ((file.size/1024)/1024).toFixed(4);
        if (filesize > 8){
            alert('Max file size')
            $(this).val('')
            return
        }
        reader.readAsDataURL(file);
        reader.onload = function () {
            imageItem.find('.image-preview img').attr('src', reader.result)
            chatBox.append(`<img class='image-preview' src="${reader.result}">`)
        };
        imageItem.find('.div-select-image').addClass('d-none')
        imageItem.find('.image-preview').removeClass('d-none')
    } else {
        chatBox.find('.image-preview').remove()
    }
})
$(document).on('click', '.image-preview .remove-image', function (event) {
    let imageItem = $(this).closest('.image-item')
    imageItem.find('.image-preview').addClass('d-none')
    imageItem.find('.div-select-image').removeClass('d-none')
    imageItem.find('.image-preview img').attr('src', '')
    imageItem.find('input.input-select-image').val('').change()
})
$(document).on('input', '.input-button-name', function (event) {
    let indexElement = $(this).closest('.option-button').attr('data-index')
    let previewElement = $('#preview-message .chat-box-body .list-message-preview .message-item .chat-box-message')
    let buttonContent = previewElement.find(`button.btn-preview-button[data-index=${indexElement}]`)
    buttonContent.text($(this).val())
})
$(document).on('input', '.input-selection-name', function (event) {
    let indexElement = $(this).closest('.option-selection').attr('data-index')
    let previewElement = $('#preview-message .chat-box-body .list-message-preview .message-item .list-selection')
    let selectionContent = previewElement.find(`button.btn-preview-selection[data-index=${indexElement}]`)
    selectionContent.text($(this).val())
})
$(document).on('input', '.textarea-package', function (event) {
    let indexElement = $(this).closest('.package-item').attr('data-index')
    let previewElement = $('#preview-message .chat-box-body .list-message-preview')
    let messageContent = previewElement.find(`.message-item[data-index=${indexElement}]`).find('.chat-box-message .message-content')
    messageContent.text($(this).val())
})
function setDataLinkAddAssociation() {
    $('.btn-add-option').data("association-insertion-method", 'append').data("association-insertion-node", function (link) {
        return link.closest('.section-item').find('.list-options')
    });
    $('.btn-add-trigger').data("association-insertion-method", 'append').data("association-insertion-node", function (link) {
        return link.closest('.button-item').find('.list-triggers')
    });
    $('.button-add-step').data("association-insertion-method", 'append').data("association-insertion-node", function (link) {
        return link.closest('.button-item').find('.list-steps')
    });
}
function previewPackage(insertedItems) {
    insertedItems.each(function (index, value) {
        let insertedItem = $(value)
        let indexElement = insertedItem.attr('data-index') || $.now()
        insertedItem.attr('data-index', indexElement)
        let previewElement = $('#preview-message .chat-box-body .list-message-preview')
        if (insertedItem.hasClass('package-item')) {
            let sectionHtml = ` 
            <div class="message-item w-16  w-100 " data-index="${indexElement}">
            <div class="d-flex gap-7">
              <div class="icon">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z" fill="#F9D2E4"/>
                  <path d="M15 7.5C13.035 7.5 11.4375 9.0975 11.4375 11.0625C11.4375 12.99 12.945 14.55 14.91 14.6175C14.97 14.61 15.03 14.61 15.075 14.6175C15.09 14.6175 15.0975 14.6175 15.1125 14.6175C15.12 14.6175 15.12 14.6175 15.1275 14.6175C17.0475 14.55 18.555 12.99 18.5625 11.0625C18.5625 9.0975 16.965 7.5 15 7.5Z" fill="#E54D93"/>
                  <path d="M18.8097 16.6125C16.7172 15.2175 13.3047 15.2175 11.1972 16.6125C10.2447 17.25 9.71973 18.1125 9.71973 19.035C9.71973 19.9575 10.2447 20.8125 11.1897 21.4425C12.2397 22.1475 13.6197 22.5 14.9997 22.5C16.3797 22.5 17.7597 22.1475 18.8097 21.4425C19.7547 20.805 20.2797 19.95 20.2797 19.02C20.2722 18.0975 19.7547 17.2425 18.8097 16.6125Z" fill="#E54D93"/>
                </svg>
              </div>
              <div class='chat-box-message w-100'>
                 <div class="message-content text-break"></div>
              </div>
            </div>
             <div class='d-flex gap-7 mt-3 mb-2 list-selection'>
             </div>
          </div>`
            if (previewElement.find(`.message-item[data-index=${indexElement}]`).length === 0) {
                previewElement.append(sectionHtml)
            }
        }
        if (insertedItem.hasClass('option-button')) {
            let indexElementPackage = insertedItem.closest('.package-item').attr('data-index')
            if (previewElement.find(`.message-item[data-index=${indexElementPackage}]`).find(`.chat-box-message .btn-preview-button[data-index=${indexElement}]`).length === 0) {
                previewElement.find(`.message-item[data-index=${indexElementPackage}]`).find('.chat-box-message').append(`<button class="btn w-100 btn-preview-button" data-index="${indexElement}"></button>`)
            }
        }
        if (insertedItem.hasClass('option-selection')) {
            let indexElementPackage = insertedItem.closest('.package-item').attr('data-index')
            if (previewElement.find(`.message-item[data-index=${indexElementPackage}]`).find(`.list-selection .btn-preview-selection[data-index=${indexElement}]`).length === 0) {
                previewElement.find(`.message-item[data-index=${indexElementPackage}]`).find('.list-selection').append(`<button class="btn-preview-selection px-2 py-1 mr-1" data-index="${indexElement}"></button>`)
            }
        }
    })

}
function removePreviewPackage(insertedItem) {
    let indexElement = insertedItem.attr('data-index')
    let messageItem = $('#preview-message .chat-box-body .list-message-preview')
    if (insertedItem.hasClass('package-item')) {
        messageItem.find(`.message-item[data-index=${indexElement}]`).remove()
    }
    if (insertedItem.hasClass('option-button')) {
        messageItem.find(`.message-item .chat-box-message button.btn-preview-button[data-index=${indexElement}]`).remove()
    }
    if (insertedItem.hasClass('option-selection')) {
        messageItem.find(`.message-item .list-selection button.btn-preview-selection[data-index=${indexElement}]`).remove()
    }
}