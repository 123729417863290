$(() => {
  $('.btn-update-tag').click(function(e) {
    var id = $(this).data('id');
    var name = $('.label-' + id).val();
    if (name === ""){
      $('.label-error-' + id).removeClass('d-none');
      e.preventDefault();
      setTimeout(function () {
        $('.label-error-' + id).addClass('d-none');
      }, 3000);
    } else {
      e.preventDefault();
      $.ajax({
        url: "/tags/" + id,
        method: "PATCH",
        data: { tag: {name: name} },
        dataType: "json",
        success: function (response) {
          if (response.success) {
              window.location.href = "/tags";
            } else {
              $('.error-modal').text("タグが既に存在しています。");
              setTimeout(function () {
                $('.error-modal').hide();
              }, 3000);
            }
          },
        error: function (xhr) {
          window.location.href = "/tags";
        },
      });
    }
  });

  $('.btn-create-tag').click(function(e) {
    var name = $('#create_tag_name').val();
    if (name === ""){
      $('.tag-error').removeClass('d-none');
      setTimeout(function () {
        $('.tag-error').addClass('d-none');
      }, 3000);
      e.preventDefault();
    } else {
      e.preventDefault();
      $.ajax({
        url: "/tags",
        method: "post",
        data: { tag: {name: name} },
        dataType: "json",
        success: function (response) {
          if (response.success) {
              window.location.href = "/tags";
            } else {
              $('#createTag').find('.tag-error-name').text("タグが既に存在しています。");
              setTimeout(function () {
                $('#createTag').find('.tag-error-name').hide();
              }, 3000);
            }
          },
        error: function (xhr) {
          window.location.href = "/tags";
        },
      });
    }
  });

});