$(() => {
  if($('.btn-signup').length > 0) {
    $('.d-flex.align-items-center.col-md-6 .radio-but-1').click();
    $('.type-account-true').click(function(e) {
      $('.company_name').addClass('d-none');
      $('.name_user').removeClass('d-none');
    });

    $('.btn-signup').click(function(e) {
      var visible =  $('#user_company_name').is(':visible');
      var name = $('#user_name').val();
      var company_name = $('#user_company_name').val();
      if (visible == true) {
        if (name === "") {
          $('.name-user').text('名前を入力してください。');
          e.preventDefault();
        }
        if (company_name === "") {
          $('.company-user').text('会社名を入力してください。');
          e.preventDefault();
        }
      } else {
        if (name === "") {
          $('.name-user').text('名前を入力してください。');
          e.preventDefault();
        }
      }
      if (!$('.checkbox-signup').prop('checked')) {
        $('.policies-term').text('利用規約に同意してください');
        var checkedElements = $('input[name="user[type_account]"]:checked');
        checkedElements.click();
        e.preventDefault();
      }
    });

    $('.type-account-false').click(function(e) {
      $('.company_name').removeClass('d-none');
      $('.name_user').removeClass('d-none');
    });
  };
});