$(document).ready(function() {
  $(".delete-tag").click(function() {
      deleteSelectedRecords();
  });

  $(".select-all").change(function(){
    $(".record-checkbox").prop("checked", this.checked);
  });

  $(".record-checkbox").change(function(){
    all = $(".record-checkbox").length;
    count = $(".record-checkbox:checked").length;
    if (all == count) {
      $(".select-all").prop("checked", true);
    } else {
      $(".select-all").prop("checked", false);
    }
  });

  function deleteSelectedRecords() {
    var selectedIds = [];
    $(".record-checkbox:checked").each(function() {
        selectedIds.push($(this).val());
    });
    if (selectedIds.length > 0) {
      if (selectedIds) {
        $.ajax({
          url: "/tags/check_delete_tag",
          method: "POST",
          data: { selected_ids: selectedIds.join(",") },
          dataType: "script"
        });
      }
    }
  }

});
