$(document).ready(function() {
  $(document).on('input keyup', '.message-input', function() {
    var input = $(this);
    if (input.val() === '') {
      input.get(0).setCustomValidity('パッケージ名を入力してください');
    } else {
      input.get(0).setCustomValidity('');
    }
  });

  $(document).on('invalid', '.list-input', function() {
    var input_list = $(this);
    if (input_list.val() === '') {
      input_list.get(0).setCustomValidity('パッケージを選択してください');
    } else {
      input_list.get(0).setCustomValidity('');
    }
  });

  $(document).on('click', '.btn-message', function(e) {
    var input = $(this).closest('form').find('.message-input');
    var input_list = $(this).closest('form').find('.list-input');
    input.trigger('input');
    input_list.trigger('invalid');
  });

});
