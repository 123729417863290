$(document).on('cocoon:before-insert', function(e, insertedItem, originalEvent) {
	var numberOfSteps = $('.label-input-package-name').length;
	var newNumber = numberOfSteps + 2;
	var labelInputPackage = insertedItem.find('.label-input-package-name');
	labelInputPackage.text('ステップ配信追加（' + newNumber +'通目からの時間差）');
});

$(document).on('cocoon:after-remove', function(e, removedItem) {
	$('.label-input-package-name').each(function(index) {
		$(this).text('ステップ配信追加（'  + (index + 2) + '通目からの時間差）');
	});
});

